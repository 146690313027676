.rounded-initials-SellerDealshome {
    color: var(--primary);
    border-color: var(--primary) !important;
    background-color: white;
    padding: .8rem;
  }
  
  /* .dropdown-toggle:after */
  
  .noCaret .dropdown-toggle:after {
    display: none;
  }

  .icon-ez-notSet {
    color:#6C757D;
    fill:#6C757D !important;
  }

  .icon-ez-filter {
    color:var(--primary);
  }