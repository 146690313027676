.supplier-selection-accordion {
  /* position: fixed; */
  width: 100%;
  top: 76px;
  left: 0;
  transition-duration: 0.2s;
  z-index: 400;
}

.supplier-selection-option-container {
  cursor: pointer;
  padding: 5px;
  border-bottom: 0.5px lightgray solid;
  border-right: 0.5px lightgray solid;
  border-left: 0.5px lightgray solid;
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.supplier-selection-option-container.active{
  background-color: var(--primary);
  color: white;
}

.supplier-selection-option-container:hover {
  background-color: var(--hover);
  color: white;
}

.selector-open {
  visibility: visible;
}

/* width */
.selector-open::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.selector-open::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--hover); 
  border-radius: 10px;
}
 
/* Handle */
.selector-open::-webkit-scrollbar-thumb {
  background: var(--primary); 
  border-radius: 10px;
}

/* Handle on hover */
.selector-open::-webkit-scrollbar-thumb:hover {
  background: var(--primary); 
}

.selector-close {
  overflow: hidden;
  visibility: hidden;
  height: 0;
}

.supplier-selection-option-accessdisabled {
  background-color: grey !important;
  border: 1px solid grey !important;

}