.select__control--is-focused {
  border: 2px solid #9ebeed !important;
  box-shadow:0 0 5px 1px #9ebeed !important;
}

.select__control{ 
  max-width: 135px !important;
}

.select__value-container{
  padding: 0 8px 0 8px !important;
  background-color: white !important;
}