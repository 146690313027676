.product-grid-item:hover {
  /* grid-column-end: span 2; */
}

.product-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  transition: all 0.3s ease;
}

.product-grid-item {
  transition: all 0.3s ease;
}

.product-grid-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.product-grid-header-expanded {
  background-color: var(--bg-primary);
  border: 2px solid var(--primary);
}


.product-grid-header-unexpanded {
  background-color: var(--primary);
  border: 2px solid white;
}

.product-grid-header:hover {
  transform: rotate(360deg);
}

.maunfacturer-selector-active {
  border: 2px solid var(--primary);
  cursor: pointer;
  border-radius: 30px;

  width: 80px;
   height: 80px;
  font-weight: 600;
  background-color: var(--primary);
}

.maunfacturer-selector {
  filter:grayscale(1) ;
  border: 2px solid var(--primary);
  cursor: pointer;
    width: 80px;
    width: 80px;
   height: 80px;
  border-radius: 30px;
  font-weight: 600;
}

.maunfacturer-selector:hover {
  filter:grayscale(0) ;

}

.maunfacturer-selector-container {
  overflow-x: auto;
}