.collapse.show.card-body .row:nth-of-type(odd) > div {
  background-color: #f2f2f2;
}

.collapse.show.card-body .row {
  flex-wrap: nowrap !important;
}

.product-stock-level-column > .row:nth-of-type(even) {
  background-color: #f2f2f2;
}

.collapse.show.card-body .row:last-of-type > div {
  background-color: #fff;
}

.collapse.show.card-body .row:last-of-type > div > div > div,
.collapse.show.card-body .row:last-of-type > div {
  border-bottom: none !important;
}

.collapse.show.card-body .row > div {
  border-bottom: 2px solid #dee2e6;
}

.header-aProduct {
  background-color: var(--primary);
  color: #fff;
}

.img-Aporduct {
  width: 10rem;
}

.product-card-scroll-body {
  max-height: 100vh;
  overflow-y: scroll;
}

.product-card-scroll-body::-webkit-scrollbar {
  display: none;
}

.product-card-max-width {
  max-width: 50%;
}

@media (max-width: 1000px) {
  .product-card-max-width {
    max-width: 100%;
  }
}

@media screen {
  .logo-product-card-header {
    display: none;
  }
}

@media print {
  .logo-product-card-header {
    width: auto;
    height: 2.5rem;
  }

  .product-card-scroll-body {
    max-height: 100%;
  }

  .product-card-max-width {
    max-width: 100%;
  }

  .card {
    box-shadow: none;
  }

  .header-aProduct {
    color: #1b9ad2;
    background-color: #fff;
    border-bottom: 1px solid #dee2e6;
  }

  .collapse.show.card-body .row > div {
    border: none;
  }

  @page {
    size: auto;
    margin: 0;
  }
}

.product-details-tranparent-background {
  background-color: transparent !important;
}

.favorite-stock-column {
  max-width: 20px !important;
}

.multiple-seller-card-header-max-width {
  max-width: 250px !important;
  min-width: 250px !important;
  position: sticky;
  left: 0px;
  opacity: 1;
  background-color: white;
}

.multiple-seller-card-product-max-width {
  max-width: 400px !important;
  min-width: 400px !important;
  align-self: flex-start;
  min-height: 31.44px;
}

.product-details-card-container {
  overflow-x: auto;
  /* max-height: calc(100vh - 174px); */
}

/* width */
.product-details-card-container::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.product-details-card-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--hover); 
  border-radius: 10px;
}
 
/* Handle */
.product-details-card-container::-webkit-scrollbar-thumb {
  background: var(--primary); 
  border-radius: 10px;
}

/* Handle on hover */
.product-details-card-container::-webkit-scrollbar-thumb:hover {
  background: var(--primary); 
}


.width-max-content {
  width: max-content;
}

.multiple-seller-card-header-max-width-colored {
  max-width: 250px !important;
  min-width: 250px !important;
  position: sticky;
  left: 0px;
  opacity: 1;
  background-color: var(--primary);
}