.card-deals {
  min-height: 4.991rem;
  margin-bottom: 0.5rem;
  border-width: 1px 1px 1px 3px;
  border-color: var(--primary);
  border-style: solid;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.card-deals-secondary {
  min-height: 4rem;
  margin-bottom: 0.5rem;
  border-width: 1px 1px 1px 3px;
  border-color: var(--primary);
  border-style: solid;
}

.card-body-dealsRow {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.link-toDocument {
  color: var(--bs-info);
}

.deals-dealname-container {
  display: flex;
}

.deals-dealname {
  /* color: var(--primary); */
}

.deals-dealname-divider {
  margin: 0px;
  color: var(--primary);
  border: 1px solid var(--primary);
  /* height: 2px !important; */
}

.deals-pill {
  color: var(--primary);
  background-color: white !important;
  border: 1px solid;
  border-color: var(--primary) !important;
}

.deals-pill-inverse {
  color: white;
  background-color: var(--primary) !important;
  border: 1px solid;
  border-color: var(--primary) !important;
}

.deal-status {
  margin-right: -7px;
}

.card-deals-invoice-card {
  min-height: 35px !important;
  transition-duration: 0.2s;
}

.tags-input {
  /* width: 80px !important; */
}

.recent-tags {
  margin-top: 5px;
  position: absolute;
  border: none !important;
  z-index: 400;
  background-color: white;
  width: 150px;
  box-shadow: 0 0 5px 0.5px lightgray;
}
