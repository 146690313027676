.loader {
  background-color: lightgray !important;
  border-color: white;
  background-image: linear-gradient(
    90deg,
    lightgray 25%,
    white 55%,
    lightgray 90%
  );
  background-size: 90px 156px;
  background-position: 0 0;
  background-repeat: no-repeat;
  animation: move 1.2s infinite;
}

.loader-small {
  background-color: lightgray !important;
  border-color: white;
  background-image: linear-gradient(
    90deg,
    lightgray 25%,
    white 55%,
    lightgray 90%
  );
  background-size: 50px 156px;
  background-position: 0 0;
  background-repeat: no-repeat;
  animation: move 1s infinite;
}

.loader-label {
  width: 100px;
  height: 12px;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 0;
  }
}

.full-screen-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background-color: var(--bs-gray);
  opacity: 0.5;
  z-index: 5000;
  display: flex;
  justify-content: space-around;
  align-items: center;
}


.path {
  stroke-width: 50;
  stroke: #1b9ad2;
  fill:  transparent;
}

.pathUp {
  animation: dashUp 2s infinite ease-in-out;
  animation-fill-mode: forwards;
}

.pathDown {
  animation: dashUp 2s infinite ease-in-out;
  animation-fill-mode: forwards;
}

.rotate-logo-svg {
display: flex;
justify-content: center;
align-items: center;
}



@keyframes dashUp {
0% {
  stroke-dashoffset: 14916.8525390625
}
  50% {
      stroke-dashoffset: 0;
  }

 100% {
  stroke-dashoffset: 14916.8525390625
}
}

@keyframes dashUp {
0% {
  stroke-dashoffset: 19466.986328125
}
  50% {
      stroke-dashoffset: 0;
  }

 100% {
  stroke-dashoffset: 19466.986328125
}
}



