/* .rdt_TableHeader {
    max-width: '2.2rem';
    min-width: '2.2rem';
    background-color: red;
} */

.supplierTalbe-initals {
  font-size: 1rem;
  width: 2.3rem;
  height: 2.3rem;
  font-weight: 400;
  background-color: #fff;
  color: var(--primary);
  border: 1px solid var(--primary);
  display: flex;
  justify-content: center;
  padding: 6px 3px 0 3px;
}

/* Icons */

.icon-supplierActiveProject {
  color: green;
}
.icon-supplierInquiry {
  color: orange;
}
.icon-supplierOffer {
  color: orange;
}
.icon-supplierOrder {
  color: orange;
}
.icon-supplierInvoice {
  color: #d9534f;
}

.icon-pricList {
  color: var(--primary);
}

.col-supplier-expandable {
  align-self: center;
}
