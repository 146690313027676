.inquiry-custom-select > button {
  border: none !important;
  background-color: white !important;
  max-width: 120px;
  padding-left: 0 !important;
  font-weight: 500;
}

.inquiry-unsent-price-cell::-webkit-outer-spin-button,
.inquiry-unsent-price-cell::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.inquiry-unsent-price-cell {
  -moz-appearance: textfield;
}

.document-header-left-col {
  max-width: 115px !important;
}

.document-unsent-closed {
  /* writing-mode: vertical-rl; */
}

.document-unsent-open {
 flex-grow: 1;
}

.document-unsent-container {
  width: 100%;
}

.document-unsent {
  /* transition-duration: 0.2s; */
 }