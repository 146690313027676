.main-onboarding {
  background-color: var(--primary);
}

.card-onboarding {
  background-color: var(--primary);
  border: none;
  border: radius 0rem;
}

.card-onboardingChild {
  background-color: #fff;
  border: none;
  border: radius 0rem;
}

.card-onboarding .card-header {
  background-color: var(--primary);
  border: none;
  border-radius: 0rem;
}

.logo-onboarding {
  width: 4rem;
}

.col-onboardingSteps {
  text-align: -webkit-center;
}

.onboardingSteps {
  background-color: var(--primary);
  color: #fff;
  border: 1px solid #fff;
  text-align: center;
  font-size: 3rem;
  width: 5rem;
  margin-bottom: 1rem;
}

.col-onboardingSteps .active {
  background-color: #fff;
  border: 1px solid var(--primary);
  text-align: center;
  color: var(--primary);
  font-size: 3rem;
  width: 5rem;
}

.card-onboardingChild .text {
  color: #fff;
}

.card-onboarding .img-onboarding {
  align-items: center;
  width: 7rem;
  height: 7rem;
  border: 1px solid var(--primary);
}

.iconPlayBack {
  transform: rotate(180deg);
}

.onboarding-intro-card {
  width: 60%;
  height: fit-content;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@media only screen and (max-width: 1500px) {
  .onboarding-intro-card {
    width: 90%;
    height: fit-content;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.terms-and-conditions-modal > .modal-dialog > .modal-content {
  width: 700px;
}

.terms-and-conditions-modal-body {
  height: 600px;
  overflow-y: scroll;
}