.buyerDealsHome {
  padding: .5rem;
}

.noCaret .dropdown-toggle:after {
  display: none;
}


.buyerDealsHome .card-header {
  padding: .5rem;
}

.rounded-initials-buyerDealshome {
    color: var(--primary);
    border-color: var(--primary) !important;
    background-color: white;
    padding: .8rem;
  }
  
  .dropdown-toggle.noCaret:after {
    display: none;
  }

  .icon-ez-notSet {
    color:#6C757D;
  }

  .icon-ez-filter {
    color:var(--primary);
  }