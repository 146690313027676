.supplier-qty-error {
  box-shadow: inset 0 0 2px 2px var(--bs-red) !important;
}

.custom-required-qty-input:focus, .custom-supplier-qty-input:focus {
  outline: none !important;
  border: none;
  box-shadow: none;
  background-color: transparent;
}


.custom-supplier-qty-input {
  background-color: transparent;
  transition-duration: 0.2s !important;
}


.custom-external-seller-input::-webkit-outer-spin-button,
.custom-external-seller-input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.supplier-qty-focused, .required-qty-focused {
  border: 2px solid #9ebeed !important;
  box-shadow: inset 0 0 5px 1px #9ebeed !important;
}