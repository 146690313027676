  .overlay-content {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    top: 16px;
    /* transform: translate(-50%, -50%); */
    /* -ms-transform: translate(-50%, -50%); */
  }

  .overlay-content > .btn-eleczap-noBorder {
    margin-left: 90px !important;
  }

  .overlay-content > .btn-eleczap-danger-noBorder {
    margin-right: 90px !important;
  }
  
  .overlay-image-container {
    position: relative;
  }

  .overlay-image-container:hover .overlay-image {
    opacity: 0.3;
  }
  
  .overlay-image-container:hover .overlay-content {
    opacity: 1;
  }
