/* .rdt_TableHeader {
    max-width: '2.2rem';
    min-width: '2.2rem';
    background-color: red;
} */

.customerTalbe-initals {
font-size: 1rem;
width: 2.3rem;
height: 2.3rem;
font-weight: 400;
background-color: #FFF;
color: var(--primary);
border: 1.5px solid var(--primary);
display: flex;
justify-content: center;
padding: 6px 3px 0 3px;

}



/* Icons */

.icon-customerActiveProject {
    color:green;
}
.icon-customerInquiry {
    color: orange;
}
.icon-customerOffer {
    color:orange;
}
.icon-customerOrder {
    color:orange;
}
.icon-customerInvoice {
    color:#D9534F;
}

.icon-pricList {
    color: var(--primary)
}

.col-customer-expandable {
    align-self: center;
}

.rdt_Table {
    overflow-x: hidden !important;
}