.project-suppliers-selector-container {

  /* background-color: #f7f9fc; */
}

.project-suppliers-selector {
  /* background-color: #f7f9fc; */

  overflow-y: auto;
  scrollbar-gutter: stable;
}

/* width */
.project-suppliers-selector::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.project-suppliers-selector::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--hover); 
  border-radius: 10px;
}
 
/* Handle */
.project-suppliers-selector::-webkit-scrollbar-thumb {
  background: var(--primary); 
  border-radius: 10px;
}

/* Handle on hover */
.project-suppliers-selector::-webkit-scrollbar-thumb:hover {
  background: var(--primary); 
}

.project-supplier-option-selected {
  background-color: var(--primary);
  color: white;
}
