.header-small > .product-class-section-header.active,
.header-small-no-margin-top > .product-class-section-header.active {
  transform: scale(1.2);
  transition-duration: 0.2s;
}

.product-class-section-header,
.product-class-section-child {
  max-width: 136px !important;
  min-width: 136px !important;
  text-align: center;
  align-content: center !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-class-section-header {
  height: 230px;
}

.product-class-section-header-unchanged {
  height: 230px;
  max-width: 136px !important;
  min-width: 136px !important;
  text-align: center;
  align-content: center !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-class-section-header-container {
  width: 93%;
}

.header-small-hidden {
  display: none;
}

.header-small {
  justify-content: flex-start !important;
  position: fixed;
  width: 100%;
  height: 85px;
  top: calc(60px + 10px);
  background-color: rgb(255, 255, 255);
  margin-top: 0.6rem !important;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: -100px; /* Negative half of height. */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  flex-wrap: nowrap !important;
  z-index: 200;
  margin-left: 0;
}

.header-small-seller {
  position: fixed;
  top: 4rem !important;
  margin-top: -2px !important;
}

.header-small > .product-class-section-header,
.header-small-no-margin-top > .product-class-section-header {
  max-width: 70px !important;
  min-width: 70px !important;
}

.header-small > .product-class-section-header > a:nth-of-type(2),
.header-small > .product-class-section-header > a:nth-of-type(3) {
  display: none;
}

.header-small-no-margin-top > .product-class-section-header > a:nth-of-type(2),
.header-small-no-margin-top > .product-class-section-header > a:nth-of-type(3) {
  display: none;
}

.header-small::-webkit-scrollbar,
.header-small-no-margin-top::-webkit-scrollbar {
  display: none;
}

.header-small::-webkit-scrollbar-track,
.header-small-no-margin-top::-webkit-scrollbar-track {
  background-color: var(--primary) !important;
  border-radius: 1rem;
  height: 5px;
  width: 5px;
}

.header-small::-webkit-scrollbar-thumb,
.header-small-no-margin-top::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 1rem;
  height: 5px;
  width: 5px;
}

.header-small-no-margin-top {
  justify-content: flex-start !important;
  position: fixed;
  height: 90px;
  width: 100%;
  top: 0 !important;
  background-color: white;
  margin-top: 0 !important;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: -90px; /* Negative half of height. */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  flex-wrap: nowrap !important;
  z-index: 200;
}

.card-wrapper {
  overflow-x: hidden;
}

.product-class-section {
  scroll-margin-top: 250px;
  padding-top: 190px;
}

.product-class-image {
  height: 136px;
  max-width: 136px !important;
  border: 2px solid lightgray;
  border-radius: 10px;
}

.header-small-no-margin-top > div > a > .product-class-image,
.header-small > div > a > .product-class-image {
  height: 56px !important;
  max-width: 60px !important;
}

.product-class-link {
  color: var(--primary);
  font-weight: 500;
  font-size: 12px;
  max-width: 136px;
  /* white-space: nowrap; */
  /* overflow-x: hidden;
  overflow-y: visible;
  text-overflow: ellipsis; */
}

.product-class-qty {
  color: var(--bs-gray);
}

/* Seller product class list styles */

.product-class-list-sticky-header-small {
  top: 90px !important;
}

.product-class-list-image {
  margin-left: 10px !important;
  max-height: 80px;
  max-width: 80px;
}


.product-class-grid-image {
  margin-left: 10px !important;
  max-height: 15rem;
  max-width: 15rem;
}

.product-class-list {
  transition-duration: 0.5s;
}

.product-class-list-expanded::-webkit-scrollbar,
.product-class-list::-webkit-scrollbar {
  display: none;
}

.header-small > .product-class-section-header.active > a > .product-class-image,
.header-small-no-margin-top
  > .product-class-section-header.active
  > a
  > .product-class-image {
  border: 2px solid var(--primary) !important;
}

.supplier-choice-chip-container {
  position: absolute;
  top: 0;
  left: 5px;
  background-color: white;
  box-shadow: 0 0 10px 2px lightgray;
  padding: 10px 5px;
  margin-top: 10px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
  overflow-x: auto;
  z-index: 1000;
  border-radius: 10px;
}

.supplier-choice-chip {
  max-height: 28.8px;
  transition-duration: 0.2s;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 0px 0;
  border: 1px solid var(--primary);
  border-radius: 50px;
  cursor: pointer;
}

.supplier-choice-chip.active {
  background-color: var(--primary);
  color: white;
  max-height: 28.8px;
  /* box-shadow: 0 0 7px 3px lightgray; */
}

.supplier-choice-chip:hover {
 background-color: var(--hover);
 color: white;
}

/* width */
.supplier-choice-chip-container::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.supplier-choice-chip-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--hover); 
  border-radius: 10px;
}
 
/* Handle */
.supplier-choice-chip-container::-webkit-scrollbar-thumb {
  background: var(--primary); 
  border-radius: 10px;
}

/* Handle on hover */
.supplier-choice-chip-container::-webkit-scrollbar-thumb:hover {
  background: var(--primary); 
}