.navbar-seller {
  height: 72px;
}

.seller-nav-item {
  text-overflow: ellipsis;
  text-align: center;
  border-radius: 0.25rem;
  background-color: transparent;
  max-width: 10rem;
  padding-bottom: 0.01rem;
  color: var(--primary);
}

.seller-customer-dropdown-control {
  cursor: text !important;
}

.seller-customer-dropdown-icon-hover {
  color: var(--hover) !important;
}

.seller-nav-item:hover {
  text-decoration: none;
  color: var(--hover) !important;
}

.seller-nav-item > span:hover {
  text-decoration: none;
  color: var(--hover) !important;
}

.seller-nav-item-icon {
  /* border: 2px var(--primary) solid !important; */
  padding: 4px;
  color: var(--primary);
  font-size: 1.3rem;
  border-radius: 4px;
}

.seller-nav-item-label {
  margin-top: -4px !important;
}

.seller-nav-item-icon:hover {
  /* border: 2px var(--hover) solid !important; */
  color: white;
}

.seller-nav-item-icon:hover {
  /* border: 2px var(--hover) solid !important; */
  color: white;
}

.seller-nav-link {
  color: var(--primary);
  text-align: center;
  min-width: 5rem;
}

.seller-nav-link:hover {
  text-decoration: none;
}

.seller-header-initials {
  background-color: white !important;
  border: 2px solid var(--primary) !important;
  font-weight: bold;
  color: var(--primary);
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 6px 0px 0px 0px;
}

.seller-header-initials > img {
  border: 2px solid var(--primary) !important;
}

.seller-header-initials:hover {
  color: var(--hover) !important;
  border: 2px solid var(--hover) !important;
}

.navbar .header-search-input {
  margin-left: 2rem;
  min-width: 10rem;
  width: 100%;
}

.seller-logo-header {
  width: 28px;
  height: 28px;
}

.caret-off::before {
  display: none;
}

.caret-off::after {
  display: none;
}

.seller-header-container-xxl {
  max-width: 100% !important;
}

.nav-container {
  flex-grow: 1 !important;
}

.search-icon-header-seller {
  border-left: 1px solid hsl(0, 0%, 80%);
  border-top: 1px solid hsl(0, 0%, 80%);
  border-bottom: 1px solid hsl(0, 0%, 80%);
  background: hsl(0, 0%, 100%);
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.header-search-input-seller > .select-product-search__control--is-focused {
  box-shadow: none !important;
  border-right: 1px solid hsl(0, 0%, 80%) !important;
  border-top: 1px solid hsl(0, 0%, 80%) !important;
  border-bottom: 1px solid hsl(0, 0%, 80%) !important;
}

.seller-nav-item-icon-white {
  /* border: 2px var(--primary) solid; */
  padding: 4px;
  color: var(--primary);
  border-radius: 4px;
  font-size: 1.3rem;
}

.seller-nav-item-white {
  text-align: center;
  color: var(--primary) !important;
  border-radius: 0.25rem;
  padding-bottom: 0.01rem;
  border-radius: 0.5rem;
}
