.rdt_TableCol_Sortable > div {
  white-space: pre-wrap !important;
}

.borderless-input {
  border: none !important;
  border-color: transparent !important;
  width: auto;
}

.borderless-input:focus {
  outline: none !important;
}

.borderless-input::-webkit-outer-spin-button,
.borderless-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.borderless-input {
  -moz-appearance: textfield;
}

.custom-project-table-input {
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 3px;
  background-color: white !important;
}

.custom-project-table-input-row {
  max-width: 40px !important;
}

.custom-project-table-input-disabled {
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 3px;
  background-color: lightgray !important;
}


div[role='columnheader'] {
  line-height: 1.5 !important;
}

.select__control {
  height: 32px;
}

.delete-attachment-toast,
.btn-undo-delete-attachment {
  background-color: var(--bs-danger) !important;
  border: none !important;
}

.offcanvas-end {
  width: 90% !important;
}

.project-totals {
  max-width: 400px;
}

@media  (max-width: 1500px) {
  .project-totals {
    max-width: 800px;
  }
}

.row-min-height {
  min-height: 31.44px;
}

.row-max-height {
  max-height: 31.44px;
}