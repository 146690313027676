.project-order-stocks-toggle {
  height: 100%;
  width: 30px;
  background-color: var(--primary);
  transition-duration: 0.2s;
  cursor: pointer
}

.project-order-stocks-toggle-text {
  writing-mode: vertical-lr;
  margin-left: 5px;
  margin-top: 5px;
}

.project-order-stocks-toggle:hover {
  height: 100%;
  width: 40px;
  background-color: var(--hover);
}