.card-login {
width:40rem;
padding: 2rem;
margin-top:20%;
}

.logo-login {
    width: 152px;
    height: 152px;
}

.btn-signIn {
    text-align: center;
}

.link-forgotPassword {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-top: 0rem;
    font-size: 90%;
}