.main-onboarding {
  background-color: var(--primary);
  margin-left: 0px !important;
}

.card-onboarding {
  background-color: var(--primary);
  border: none;
  border: radius 0rem;
}

.card-onboardingChild {
  background: var(--primary) !important;
  border: none;
  border: radius 0rem;
}

.card-onboarding .card-header {
  background-color: var(--primary);
  border: none;
  border-radius: 0rem;
}

.logo-onboarding {
  width: 4rem;
}

.col-onboardingSteps {
  text-align: -webkit-center;
}

.onboardingSteps {
  background-color: var(--primary);
  color: #fff;
  border: 1px solid #fff;
  text-align: center;
  font-size: 3rem;
  width: 5rem;
  margin-bottom: 1rem;
}

.col-onboardingSteps .active {
  background-color: #fff;
  border: 1px solid var(--primary);
  text-align: center;
  color: var(--primary);
  font-size: 3rem;
  width: 5rem;
}

.card-onboardingChild .text {
  color: #fff;
}

.card-onboarding .img-onboarding {
  align-items: center;
  width: 7rem;
  height: 7rem;
  border: 1px solid var(--primary);
}

.iconPlayBack {
  transform: rotate(180deg);
}
