.offer-body-info-container {
  min-height: 150px !important;
}

.offer-comparison-main-table-container {
  max-width: 59%;
  position: sticky;
  left: 10px;
  z-index: 1000;
  height: 100vh;
}

.offer-comparison-supplier-table-container {
  transition-duration: 0.2s;
  max-width: 500px;
}

.offer-comparison-supplier-table-containe-expanded {
  transition-duration: 0.2s;
  min-width: 800px !important;
}

.offer-comparison-supplier-table-main {
  margin-left: 10px;
}

.offer-comparison-container {
  height: calc(100vh - 115.45px) !important;
}
/* width */
.offer-comparison-container::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.offer-comparison-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--hover); 
  border-radius: 10px;
}
 
/* Handle */
.offer-comparison-container::-webkit-scrollbar-thumb {
  background: var(--primary); 
  border-radius: 10px;
}

/* Handle on hover */
.offer-comparison-container::-webkit-scrollbar-thumb:hover {
  background: var(--primary); 
}