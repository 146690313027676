:root {
  --primary: #1b9ad2;
  --secondary: #ffffff;
  --bg-primary: #ffffff;
  --bg-secondary: #1b9ad2;
  --green: #28a745;
  --hover: #1783b3;
}

/*Header scrolling is fixed to top*/

#root,
body,
html {
  height: unset;
}

.DayPickerInput-Overlay {
  background-color: white;
  z-index: 6 !important;
}

.buyer-supplier-selector-portal-receiver-opened {
  z-index: 3000;
  width: 200px;
}
.buyer-supplier-selector-portal-receiver-closed {
  width: 0px;

}

.buyer-supplier-selector-portal-receiver {
  background: var(--secondary);
  transition-duration: 0.2s;
  position: fixed;
  margin-left: 60px;
  height: 100vh;
  overflow-y: auto;
}
.shorter-height-fields {
  height: 22px !important;
}

.shorter-height-fields.form-select {
  font-size: 12px !important;
  height: 26px !important;
}

.wrap-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom-focused {
  border: 1.5px solid #9ebeed !important;
  box-shadow:0 0 5px 1px #9ebeed !important;
}

.btn.disabled {
  color: white !important;
}

/* Portals receiver css */
.fixed-portal-receiver {
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 400;
  background-color: #f7f9fc;
}

.fixed-portal-receiver-sticky {
  position: sticky !important;
  top: 46px;
  width: 100%;
  z-index: 400;
  background-color: #f7f9fc;
}

/* Intro JS custom css */

.introjs-initial-tooltip {
  top: 50vh !important;
  left: 50% !important;
  position: fixed !important;
}

.introjs-button,
.introjs-nextbutton,
.introjs-prevbutton {
  background-color: var(--primary) !important;
  color: var(--bg-primary) !important;
  text-shadow: none !important;
}

.intro-guide-start {
  position: absolute;
  height: 40vh;
  width: 83%;
  background-color: transparent;
  z-index: -100;
}

/*General components*/


.col-side-menu {
  max-width: 240px !important;
  min-width: 240px !important;
}

.col-side-menu-supplier-customer {
  max-width: 257px !important;
  min-width: 257px !important;
}

.center-spinner {
  margin: 50vh auto auto auto;
 
}

.btn-floating-help {
  position: fixed;
  z-index: 4000;
  left: 8px;
  top: 93vh;
  background-color: var(--primary);
  fill: var(--bg-primary);
  border-radius: 50px;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .btn-floating-help {
    display: none;
  }
}

.btn-dealsBy {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  min-width: 5rem;
}

.dropdown-dealsBy {
  margin-right: 2rem;
}

.eleczap-toast {
  margin-top: 10rem;
}

.img-company-logo {
}

.eleczap-primary {
  color: var(--primary);
  float: right;
}

.fixedElement {
  position: fixed;
  top: 0;
  width: 83%;
  z-index: 100;
  padding-top: 100px;
}

.row.sticky + .buyerHeader {
  padding-top: 90px;
}

.btn-eleczap-noBorder {
  background-color: transparent !important;
  color: var(--primary);
}

.btn-eleczap-noBorder:hover {
  border: 1px solid var(--primary);
  color: var(--primary);
}

.btn-eleczap-danger-noBorder {
  background-color: none;
  color: red;
}
.btn-eleczap-danger-noBorder:hover {
  border: 1px solid red;
  color: red;
}

.btn-eleczap-neg {
  background-color: var(--secondary);
  color: var(--primary);
  border: 1px solid var(--primary);
}

.btn-eleczap-neg:hover {
  background-color: var(--primary);
  color: var(--secondary);
}

.btn-eleczap {
  background-color: var(--primary) !important;
  color: var(--secondary);
  border: 1px solid var(--primary) !important;
}

.btn-eleczap:hover {
  background-color: var(--secondary) !important;
  color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
}

.btn-eleczap-noHover {
  background-color: var(--primary);
  color: var(--secondary);
  border: 1px solid var(--primary);
}

.btn-eleczap-noHover:hover {
  background-color: var(--primary);
  color: var(--secondary);
  border: 1px solid var(--primary);
  cursor: initial;
}

.link-eleczap {
  color: var(--primary) !important;
}

.pill-seleczap {
  color: var(--primary);
}

.nav-item-eleczap {
  color: var(--primary);
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.select-dropdown-no-left-radius {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.breadcrumb {
  /* position: fixed !important; */
  z-index: 230;
  width: 100%;
  top: 0px;
  left: 0px;
  padding-right: 18px !important;
  padding-left: 18px !important;
  background-color: #f7f9fc;
  height: 2.2rem;
  margin-bottom: 0px !important;
}

.seller-breadcrumb {
  position: fixed !important;
  z-index: 230;
  width: 100%;
  top: 65px;
  left: 65px;
  padding-right: 18px !important;
  padding-left: 18px !important;
  background-color: #f7f9fc;
  height: 2.2rem;
  margin-bottom: 0px !important;
}

.required:after {
  content: ' *' !important;
  color: red !important;
}

/*Buyer general */

.buyer-nav-link-project {
  text-overflow: ellipsis;
  padding-top: 0.1rem;
  padding-bottom: 0.01rem;
}

.nav-userSettings {
  background-color: white;
  margin: auto;
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.55rem;
  font-size: 1rem;
  color: var(--primary);
}

/* .nav-userSettings:hover {

    } */

.userTable-initials {
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  font-weight: 400;
  background-color: #fff;
  color: var(--primary);
  border: 1.5px solid var(--primary);
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 4px 0px 0px 0px;
}

.profileImage {
  width: 2rem;
  height: 2rem;
}

.account-initials {
  font-size: 3.5rem;
  width: 128px;
  height: 128px;
  font-weight: 400;
  background-color: #fff;
  color: var(--primary);
  border: 1.5px solid var(--primary);
  flex: 1;
  display: flex;
  justify-content: center;
  margin: auto;
  padding-top: 0.5rem;
}

.account-initials-left-aligned {
  font-size: 3.5rem;
  width: 128px;
  height: 128px;
  font-weight: 400;
  background-color: #fff;
  color: var(--primary);
  border: 1px solid var(--primary);
  flex: 1;
  display: flex;
  justify-content: center;
  margin-left: 10px;
  margin-bottom: 1.5rem;
  padding-top: 0.5rem;
}

/*Buyer Product detail*/

.toast-product {
  background-color: white;
  background: white;
}

.active-projects-modal {
  max-height: 15rem;
  overflow: auto;
}

.search-qty-input-group {
  min-width: 8rem;
}

.detail-qty-input-group {
  max-width: 8rem;
  margin-left: auto;
}

.product-quantity {
  width: 6rem;
  border-radius: 0px;
  font-size: 0.8rem;
  font-weight: 400;
  opacity: 1;
  min-width: 6rem;
  text-align: center;
  border: 1px solid var(--primary);
}

.img-list-small {
  width: 30px;
  height: 30px;
  background: white;
  background-size: cover;
}

.img-list-small:hover {
  transform: scale(5.5);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  z-index: 100;
}

input::-webkit-inner-spin-button {
  opacity: 1;
}

.DayPickerInput-Overlay {
  left: auto !important;
  right: 0;
}

.offer-comparison-day-picker {
  left: 0 !important;
  right: auto
}

/*Buyer deal page */

.header-dealWithDocError {
  background-color: var(--primary);
  margin: 2rem;
  text-align: center;
}

.dealWithDocError {
  color: var(--secondary);
}

/*Buyer deal table*/

.col-active-moq {
  width: 7rem;
}

.col-available-moq {
  width: 7rem;
}
/*Buyer Search*/

.favourite-star {
  /*text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;*/
  font-size: 1.3rem;
  color: var(--primary);
}

.col-search-img {
  max-width: 10rem;
}
/*Buyer Deal Home*/

.filter-eleczap-deals > button {
  padding: 0 !important;
  background-color: transparent;
  border: none;
  color: var(--primary);
}

.btn-eleczap-neg-deal {
  background-color: var(--secondary);
  color: var(--primary);
  border: 1px solid var(--primary);
}

.btn-eleczap-neg-inquiry {
  background-color: var(--secondary);
  color: var(--primary);
  border: 1px solid var(--primary);
}

.btn-eleczap-neg-offer {
  background-color: var(--secondary);
  color: var(--primary);
  border: 1px solid var(--primary);
}

.btn-eleczap-neg-order {
  background-color: var(--secondary);
  color: var(--primary);
  border: 1px solid var(--primary);
}

.btn-eleczap-neg-invoice {
  background-color: var(--secondary);
  color: var(--primary);
  border: 1px solid var(--primary);
}

.activeFilter {
  background-color: var(--primary);
  color: var(--secondary);
}

.status-icon-success {
  color: forestgreen;
}

.status-icon-danger {
  color: red;
}

.status-icon-warning {
  color: orange;
}

.status-icon-primary {
  color: var(--primary);
}

.status-icon-danger-img {
  border: 1px solid transparent;
  width: 13px;
}

.status-icon-warning-img {
  border: 1px solid transparent;
  width: 13px;
}

.status-icon-primary-img {
  border: 1px solid transparent;
  width: 13px;
}

.filter-icon {
}

.btn-status-filter {
}

.switch-eleczap {
  color: var(--secondary);
  background-color: var(--bg-secondary);
  cursor: pointer;
  padding-left: 2.5rem;
  margin-bottom: 0rem;
}

.form-check-input-elezap {
  margin-top: 0.6rem;
}

.title-deal {
  color: black;
}
.title-deal:hover {
}

.col-multi-document {
  margin-left: 0rem;
  margin-right: 0rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

.row-multi-document {
  border: 1px solid #dee6ed !important;
  border-radius: 5px 5px 0px 0px;
  margin-left: 0rem;
  margin-right: 0rem;
  padding: 0.2rem;
}

.row-documentHome {
}

.row-current-deal {
}

.row-documentHome .col-current-deal {
  min-width: 3rem;
  max-width: 3rem;
  margin: auto;
}

.btn-eleczap-copyDeal {
  color: var(--primary);
  font-size: 0.9rem;
}

.btn-eleczap-copyDeal:hover {
  border: 1px solid var(--primary);
  border-radius: 5px;
  color: var(--primary);
  background: var(--secondary);
}

.btn-eleczap-deal-disabled {
  color: var(--primary);
  font-size: 0.9rem;
}

.btn-eleczap-deal-disabled:hover {
  border: 1px solid var(--primary);
  border-radius: 5px;
  color: var(--primary);
  background: var(--secondary);
}

.btn-eleczap-deal-current {
  background-color: var(--primary);
  color: var(--secondary);
  border: 1px solid var(--primary);
}

.btn-eleczap-deal-current:hover {
  background-color: var(--secondary);
  color: var(--primary);
}

.row-documentHome .Current {
  border: 1px solid var(--primary);
}

.row-documentHome .Deal {
  min-width: 20rem;
  max-width: 20rem;
}

.row-documentHome .Inquiry {
  padding-left: 6px;
  padding-right: 6px;
}

.row-documentHome .Offer {
  padding-left: 6px;
  padding-right: 6px;
}

.row-documentHome .Order {
  padding-left: 6px;
  padding-right: 6px;
}

.row-documentHome .Invoice {
  padding-left: 6px;
  padding-right: 6px;
}

.row-documentHome .col-dealName {
  padding-right: 0rem;
}

.row-documentHome .col-dealStatus {
  padding-left: 0rem;
}

.row-documentHome .col-dealCompany {
  overflow: hidden;
  text-overflow: ellipsis;
}

.row-documentHome .col-dealCompany:hover {
  overflow-wrap: normal;
}

.row-documentHome .rounded-initials {
  font-size: 0.8rem;
  min-width: 2rem;
  min-height: 2rem;
  max-width: 2rem;
  max-height: 2rem;
  font-weight: 400;
  background-color: #f7f9fc;
  flex: 1;
  display: flex;
  justify-content: center;
  margin-left: auto;
}

.rounded-initials {
  color: var(--primary);
  border-color: var(--primary) !important;
  background-color: white;
  font-size: 0.8rem;
  min-width: 1.8rem;
  min-height: 1.8rem;
  max-width: 1.8rem;
  max-height: 1.8rem;
  flex: 1;
  display: flex;
  justify-content: center;
  /* margin-left: auto; */
  padding-top: 0.2rem;
}

.rounded-initials-x-small {
  color: var(--primary);
  border-color: var(--primary) !important;
  background-color: white;
  font-size: 0.7rem;
  min-width: 1.2rem;
  min-height: 1.2rem;
  max-width: 1.2rem;
  max-height: 1.2rem;
  flex: 1;
  display: flex;
  justify-content: center;
  /* margin-left: auto; */
  /* padding-top: 0.2rem; */
}

.col-documentHome {
}

.row-sticky {
  position: sticky;
  top: 80px;
  z-index: 900;
  background: #fff;
}

.col-documentHome-filter {
  padding-left: 2px;
  padding-right: 2px;
  margin-top: 1rem;
}

.col-documentHome-filter-deals {
  min-width: 20rem;
  margin-top: 1rem;
}

.card-documentHome-filter {
  border: 1px solid black;
}

/*Seller deals home*/

.sellerDealsHome {
  padding: 0.5rem;
}

.sellerDealsHome .card-header {
  padding: 0.5rem;
}

.btn-eleczap-project-disabled {
  color: var(--primary);
}

.btn-eleczap-project-disabled:hover {
  border: 1px solid var(--primary);
  border-radius: 5px;
  color: var(--primary);
  background: var(--secondary);
}

.btn-eleczap-project-current {
  background-color: var(--primary);
  color: var(--secondary);
  border: 1px solid var(--primary);
}

.btn-eleczap-current:hover {
  background-color: var(--secondary);
  color: var(--primary);
}

.card-list-project {
  min-height: 45rem;
}

.row-projectHome {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: 0 0.125rem 0.25rem #c4c4c4;
}

/*Seller Customers Home*/

.card-customerHome {
  min-height: 35rem;
}

.card-header-document {
  margin-top: 2rem;
  margin-left: 3rem;
  margin-right: 3rem;
}
.card-body-document {
  margin-left: 3rem;
  margin-right: 3rem;
}

/*Seller dashboard */

.card-dashboard-notifications {
  min-height: 35rem;
}

.dashboard-badge {
  margin-left: 0.5rem;
}

.dashboard-table-pagination {
  margin-left: 30rem;
  margin-top: 0.5rem;
}

.dashboard-table-badge {
  margin-right: 0.5rem;
}

.dashboard-table-UpdatesCount {
  margin-top: 0.5rem;
}

/*END*/
.input-header-search {
  width: 50rem;
  max-width: 60rem;
}

.row-header-list-project {
  margin-bottom: 1rem;
}

/* Product Classes page*/
.img-classes {
  width: 120px;
  height: 120px;
  background: white;
  position: absolute;
  z-index: 4;
}

/*    .img-classes:hover {
        transform: scale(2.5);
        box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
        z-index: 100;
   
    }*/

/* Product detail page */

.img-detail {
  max-width: 160px;
  max-height: 160px;
}
/*
.img-detail {
    width: 120px;
    height: 120px;
    background: white;
    position: absolute;
    z-index: 4;
}

.img-detail:hover {
    transform: scale(2.5);
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
    z-index: 100;
}
*/

.card-list-product {
  max-width: 80rem;
}

.card-list-product .inStockValue {
  font-weight: 700;
}

.card-list-product .priceUnitValue {
  font-weight: 700;
}

/* Product detail page*/

.detail-priceInfo {
  font-size: 16px;
  color: black;
}

.detail-unitInfo {
  font-size: 10px;
  color: black;
}

.col-detail-first-stickyHeader {
  max-width: 10rem;
  text-align: center;
}

.col-detail-stickyHeader {
  max-width: 5.5rem;
  text-align: end;
}

.col-detail-first {
  max-width: 10rem;
  text-align: end;
}

.col-detail-header {
  height: 2.5rem;
  text-align: center;
}

.col-detail {
  text-align: center;
}

.col-detail-input {
  max-width: 5.5rem;
  text-align: end;
}

.col-detail-value {
  border: 1px solid white;
  border-radius: 0.25rem;
  text-align: center;
}

.productDetail-col {
  border-radius: 0.25rem;
  color: black;
}

/*Product search page*/

.search-priceInfo {
  color: black;
}

.search-unitInfo {
  font-size: 10px;
  color: black;
}

.col-search-first-stickyHeader {
  max-width: 8rem;
  text-align: center;
}

.col-search-stickyHeader {
  max-width: 5.5rem;
  text-align: center;
}

.card-list-product .priceUnit {
  max-width: 8rem;
}

.col-search-first {
  max-width: 8rem;
  text-align: end;
  color: black;
}

.col-search-header {
  font-weight: 600;
  height: 2.5rem;
  text-align: center;
}

.col-search {
  max-width: 5.5rem;
  text-align: end;
  color: black;
}

.col-search-input {
  max-width: 5.5rem;
  text-align: end;
}

.col-search-value {
  font-weight: 600;
  border: 1px solid white;
  border-radius: 0.25rem;
  text-align: center;
  padding: 0.1rem;
}

.product-search-col {
  border-radius: 0.25rem;
  color: black;
}

.col-favourite-btn {
  text-align: end;
  margin-top: 2.5rem;
  max-width: 4rem;
  min-width: 4rem;
}

.img-list {
  max-width: 100px;
  max-height: 100px;
  background: white;
  background-size: cover;
}

.img-list:hover {
  transform: scale(2.5);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  z-index: 100;
  position: absolute;
}

.card-InCol-document-Home {
  border: 1px solid #c4c4c4;
  border-radius: 0.25rem;
  margin-top: 1rem;
}

.col-document-Home {
  min-height: 60rem;
}

.search-input-project {
  background-color: white;
  max-width: 20rem;
}

.search-input-document {
  background-color: white;
  max-width: 20rem;
  margin-bottom: 1rem;
}

.col-dashboard-intro {
  font-size: 1rem;
  text-align: center;
}

.table-dashboard-table {
  margin: 1rem;
}

.card-dashboard-table {
}

.card-detail-description {
  margin: 1rem;
}

.col-parameter-title {
  font-weight: bold;
}

.col-parameter-value {
}

.row-product-parameter {
}

.row-product-parameter:hover {
  background-color: aliceblue;
}

.company-list-group-item {
  border: none;
  width: 20rem;
}

.company-list-group-item:hover {
  background-color: cornflowerblue;
  color: white;
}

.productGroup-list-group-item {
  border: none;
  width: 20rem;
}

.productGroup-list-group-item:hover {
  background-color: cornflowerblue;
  color: white;
}

.col-classList-item {
  text-align: left;
  font-size: 0.8rem;
  padding: 6px;
}

.row-breadcrumb {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

/*Global lists Cards*/

.card-product-all {
  min-height: 8rem;
  margin-bottom: 1rem;
}

.card-product-lvl-2 {
  width: 100%;
}

.img-category {
  margin-left: auto;
}

/* Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification
for details on configuring this project to bundle and minify static web assets. */
a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.btn-light {
  background-color: #fff;
  color: #1b6ec2;
  border: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--primary);
  border-color: #1861ac;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.border-top {
  border-top: 1px solid #e5e5e5;
}
.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

button.accept-policy {
  font-size: 1rem;
  line-height: inherit;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}

/* Navbar customization*/
.navbar-custom {
  background-color: #c4c4c4 !important;
}

.navbar-btn {
  color: #20a0e9;
  border-color: #20a0e9;
}

.bg-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  background-color: #20a0e9;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  margin-left: 1rem;
}

.search-box {
  width: 20rem;
}

/* Wireframe effect */
.wireframe {
  margin-top: 1rem;
  min-height: 1000px;
  border-color: #20a0e9;
  border-radius: 5px;
  border-width: 2px;
}

.wireframe2 {
  margin-top: 1rem;
  background-color: #c4c4c4;
  color: white;
  border-color: #20a0e9;
  border-radius: 5px;
  border-width: 2px;
  vertical-align: top;
  border-right: 1rem solid white;
}

.filtersheader {
  font-size: 1.5rem;
}

/*Settings page */

.settingsPage {
  background: none;
  color: black;
  box-shadow: none;
}

.settingsPage .nav-link {
  color: black;
}

.settingsPage .form-label {
  padding-left: 0rem;
  padding-right: 0rem;
  margin-top: 1rem;
}

.tabContainerSettings {
  background: #fff;
  max-width: 20rem;
}

/* Profile image upload */
.userImage {
  width: 128px;
  height: 128px;
}

.avatar {
  width: 3rem;
  height: 3rem;
  margin-bottom: -15px;
  margin-top: -15px;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input-group-is-invalid {
  border: 1px solid red;
  align-items: center;
  border-radius: 3px;
}

.thick-rounded-border-primary {
  border: 1.5px solid var(--primary);
}