.navbar-buyer {

  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  height: 47px;
}

.buyer-nav-item {
  text-align: center;
  color: var(--primary);
  border-radius: 0.25rem;
  padding-bottom: 0.01rem;
  border-radius: 0.5rem;
}

.buyer-nav-item-white {
  text-align: center;
  color: var(--secondary);
  border-radius: 0.25rem;
  padding-bottom: 0.01rem;
  border-radius: 0.5rem;
}

.buyer-nav-item:hover {
  text-decoration: none;
}

.buyer-nav-item-label {
  margin-top: -4px !important;
}

.buyer-nav-link {
  text-align: center;
  min-width: 5rem;
  color: var(--primary);
}

.buyer-nav-item-icon-white {
  /* border: 2px var(--primary) solid; */
  padding: 4px;
  color: var(--primary);
  border-radius: 4px;
  font-size: 1.3rem;
}

.buyer-nav-item-icon {
  border: 2px var(--primary) solid;
  padding: 4px;
  color: var(--primary);
  border-radius: 4px;
  font-size: 1.3rem;
}
.buyer-nav-item-icon:hover, .buyer-nav-item-icon-white:hover{
  color: var(--hover);
}

.buyer-nav-item-icon-with-text {
  text-align: center;
  /* border: 2px var(--primary) solid; */
  border-radius: 4px;
  padding: 0px 4px 0px 4px;
  color: var(--primary);
}

.buyer-nav-item-icon-with-text-child {
  padding: 0.2rem;
  color: var(--primary);
  font-size: 1.3rem;
}

.buyer-nav-link:hover {
  text-decoration: none;
}

.buyer-nav-link-project {
  color: var(--primary);
}

.buyer-nav-link {
  color: var(--primary);
}

.buyer-nav-link:hover {
  text-decoration: none;
}

.buyer-header-initials {
  border: 2px solid white !important;
  font-weight: bold;
  background-color: var(--primary) !important;
  color: white;
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 6px 0px 0px 0px;
}

.buyer-header-initials > img {
  border: 2px solid white !important;
}

.buyer-header-initials:hover,
.buyer-header-initials > img:hover {
  background-color: var(--hover) !important;
  color: white !important;
  border: 2px solid white !important;
}

.navbar .header-search-input {
  margin-left: 0;
  max-width: 100%;
  min-width: 10rem;
  width: 95%;
}

.buyer-logo-header {
  width: 3rem;
  height: 3rem;
  padding-top: 0.15rem;
}

.caret-off::before {
  display: none;
}

.caret-off::after {
  display: none;
}

.buyer-header-container-xxl {
  max-width: 100% !important;
}

.nav-container {
  flex-grow: 1 !important;
}

.select-product-search__input-container {
  cursor: text !important;
}

.select-product-search__menu {
  min-width: 600px !important;
}

.select-product-search__option {
  cursor: pointer !important;
}

.header-search-input > .select-product-search__control {
  border-left-width: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.select-product-search__control--is-focused {
  border-bottom: 0.5px solid lightgray !important; 
    border-top: 0.5px solid lightgray !important;  
     border-right: 0.5px solid lightgray !important;  

}

.search-icon-header {
  background: hsl(0, 0%, 100%);
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.btn-all-search-results {
  cursor: pointer;
  padding: 10px;
  width: 100%;
}

.btn-all-search-results:hover {
  background-color: var(--primary);
  color: white !important;
}

.buyer-header-seller-switch-label {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.64);
}
.buyer-header-seller-switch-value {
  font-size: 16px;
  color: var(--secondary);
}

.btn-seller-switch-modal {
  cursor: pointer;
  color: var(--secondary);
  height: 29px;
  width: 29px;
}

.btn-seller-switch-modal:hover {
  background-color: var(--hover);
}
.select-product-search__control--is-focused {
  /* border: none !important; */
  box-shadow: none !important;
}


#responsive-navbar-nav-buyer {
  padding-right: 90px;
  position: absolute;
  top: 72px;
  right: 5px;
  background-color: var(--primary);
}

.recent-projects {
  position: absolute;
  top: 40px;
right: 0px;
  border: none !important;
  background-color: white;
  width: 150px;
  box-shadow: 0 0 5px 0.5px lightgray;
}


.projects-input:focus {
  outline: none !important;
  border: none;
  box-shadow: none;
}

.current-project-dropdown-icon:hover,.current-project-name-icon:hover {
  /* background-color: var(--hover); */
}

.nav-item-rounded,.nav-item-rounded-white {

  border-radius: 50px;
}

.nav-item-rounded:hover {
  background: var(--hover);
}

.nav-item-rounded-white:hover {
  background: var(--hover);
  color: white !important;
  fill: white !important;
}

.nav-item-rounded-active {
  background: var(--hover);
  transform: scale(1.2);
  transition-duration: 0.2s;
}

div[aria-labelledby=buyerSettings] {
  margin-left:  -156px !important;
}

/* .offcanvas.offcanvas-end {
  z-index: 3600 !important;
}

.offcanvas-backdrop.show {
  z-index: 3550 !important;
} */