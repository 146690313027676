.product-class-section-header-container {
  width: 100%;
}

.header-small > .product-class-section-header,
.header-small-no-margin-top > .product-class-section-header {
  max-width: 80px !important;
  min-width: 80px !important;
}

.header-small > .product-class-section-header > a:nth-of-type(2),
.header-small > .product-class-section-header > a:nth-of-type(3) {
  display: none;
}

.header-small-no-margin-top > .product-class-section-header > a:nth-of-type(2),
.header-small-no-margin-top > .product-class-section-header > a:nth-of-type(3) {
  display: none;
}

.card-wrapper {
  overflow-x: hidden;
}

.header-small-no-margin-top > div > a > .product-class-image,
.header-small > div > a > .product-class-image {
  max-height: 56px !important;
  max-width: 60px !important;
}

.product-class-link {
  /* color: var(--primary);
  font-weight: 500;
  font-size: 18px; */
  /* max-width: 136px;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: visible;
  text-overflow: ellipsis; */
}

.product-class-qty {
  color: var(--bs-gray);
}

/* Seller product class list styles */

.buyer-product-class-list-sticky-header {
  z-index: 200;
  width: 100%;
  top: 75px;
}

.buyer-product-class-list-sticky-header-small {
  top: 70px !important;
}

.product-search-sticky-header {
  z-index: 200;
  width: 100%;
  top: 63px;
  padding-right: 18px !important;
  background-color: #f7f9fc;
}

.product-class-list {
  transition-duration: 0.5s;
}

.product-class-list-expanded::-webkit-scrollbar,
.product-class-list::-webkit-scrollbar {
  display: none;
}
